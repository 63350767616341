import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import { FaCode } from 'react-icons/fa'
import { StaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'

let StyledImg = styled(props => <Img {...props}/>)`
  perspective: 1500px;
  perspective-origin: left center;
  overflow: visible !important;
  picture, img {
    transform: rotateY(-35deg) rotateX(15deg);
    box-shadow: 25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2);
    border-radius: .625rem;
    transition: 1s !important;
    &:hover {
      transform: rotateY(-30deg) rotateX(15deg);
    }
  }
`

let Client = ({title, logo}) => (
    <Col className="client my-auto">
        <img src={logo} alt={title} width="170px"/>
    </Col>
)

let Clients = ({data}) => (
  <Container className="py-5">
    <Row className="d-flex justify-content-center row">
      <Col md="12">
        <div className="mb-4 text-center">
          <h2>Our Clients</h2>
        </div>
      </Col>
      <Col md="12">
        <Row>
            <Client title="Fidelity" logo="/clients/Fidelity.png"/>
            <Client title="The Peninsula Hotels" logo="/clients/The_Peninsula_Hotels.png"/>
            <Client title="CFA Insitiute" logo="/clients/CFA.png"/>
            <Client title="Swire Properties" logo="/clients/Swire.png"/>
            <Client title="Cityplaza" logo="/clients/Cityplaza.png"/>
            <Client title="Pacific Place" logo="/clients/Pacific_Place.png"/>
            <Client title="Citygate Outlets" logo="/clients/Citygate_Outlets.png"/>
            <Client title="HKUMed" logo="/clients/HKUMed.png"/>
            <Client title="Soupism" logo="/clients/Soupism.png"/>
        </Row>
      </Col>
    </Row>
  </Container>
)

export default () => (
  <StaticQuery
    query={graphql`
      query ClientsQuery {
        file(relativePath: {eq: "sample.png"}) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Clients data={data}/>
    )}
  />
)
